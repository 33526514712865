import { Component, OnInit } from '@angular/core';
import { GetVideosService } from './get-videos.service';
import { VideoGroup } from './video-group';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { Video } from './video';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube-videos.component.html',
  styleUrls: ['./youtube-videos.component.scss']
})
export class YoutubeVideosComponent implements OnInit {

  constructor(private getVideosService: GetVideosService, private route: ActivatedRoute, private domSanitizer: DomSanitizer) { }

  videoGroups: Array<VideoGroup> = [];
  selectedVideoGroup: VideoGroup = null;

  date = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  showAll = this.route.snapshot.queryParamMap.get('show-all') || null;

  ngOnInit() {
    this.getVideosService.getVideoGroups().subscribe(
      (response: Array<VideoGroup>) => {
        this.videoGroups = response;
        const id = this.route.snapshot.paramMap.get('id');
        this.selectedVideoGroup = this.videoGroups.find((videoGroup) => videoGroup.id === id);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  filterVideos(videos: Video[]): Video[] {
    return videos.filter( video => this.showAll || !video.date || video.date === this.date);
  }
}
